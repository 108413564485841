// src/components/ActivityIndicator.tsx
import React from 'react';
import styles from '../styles/ActivityIndicator.module.css'

const ActivityIndicator = () => {
  return (
    <div className="activity-indicator"></div>
  );
};

export default ActivityIndicator;
