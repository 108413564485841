// src/pages/ConfirmNewAccountVerificationPage.tsx
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import ActivityIndicator from '../components/ActivityIndicator';
import { auth, db } from '../utils/firebaseConfig';
import "../styles/VerifyEmail.css";

const ConfirmNewAccountVerificationPage: React.FC = () => {
  const [verificationStatus, setVerificationStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const ConfirmNewAccountVerificationPage = async () => {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');

      if (!token) {
        setVerificationStatus('error');
        return;
      }

      try {
        const response = await fetch(`https://${process.env.REACT_APP_REGION}-${process.env.REACT_APP_PROJECT_ID_CYBERHEADLINERS}.cloudfunctions.net/confirmNewAccountVerification`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        if (!response.ok) {
          console.error('Verification failed:', await response.text());
          setVerificationStatus('error');
          return;
        }

        // Set verificationStatus to 'success' immediately upon successful response
        setVerificationStatus('success');

        // Optional: Listen to emailVerificationTokens/{uid}/isVerified
        const uid = auth.currentUser?.uid;
        if (uid) {
          const tokenDocRef = doc(db, `emailVerificationTokens/${uid}`);

          const unsubscribe = onSnapshot(tokenDocRef, (docSnapshot) => {
            const tokenData = docSnapshot.data();
            if (tokenData?.isNewAccountVerified) {
              // Delay navigation to allow user to read success message
              setTimeout(() => navigate('/'), 3000);
            }
          });

          return () => unsubscribe();
        } else {
          console.warn('User is not logged in. Verification process completed successfully.');
        }

      } catch (error) {
        console.error('Error verifying email:', error);
        if (error instanceof TypeError && error.message === 'Failed to fetch') {
          console.error('Network error: Could not reach the server. Please check your internet connection and try again.');
        }
        setVerificationStatus('error');
      }
    };

    ConfirmNewAccountVerificationPage();
  }, [location, navigate]);

  return (
    <div className="main-container">
      <div className="verify-email-container">
        <img src={require('../images/cyberheadliners_logo_640x446.png')} className='verify-email-logo' />
        <div><small>by Orbital Softworks</small></div>
        <div>You are verifying your email for a new subscription account with CyberHeadliners</div>
        {verificationStatus === 'loading' && (
          <>
            <div className='h1'>Verifying your email. Please wait...</div>
            <ActivityIndicator />
          </>
        )}
        {verificationStatus === 'success' && (
          <>
            <div className='h1 success'>Email verified successfully!</div>
            <div>Return to your opened CyberHeadliners page, or <Link to={'/'}>open in this window</Link>.</div>
          </>
        )}
        {verificationStatus === 'error' && (
          <>
            <div className='h1 error'>Verification failed</div>
            <div>There was an error verifying your email. Please try again or contact support.</div>
            <a className="button" href='https://cyberheadliners.com'>Return to CyberHeadliners</a>
            </>
        )}
      </div>
    </div>
  );
};

export default ConfirmNewAccountVerificationPage;
