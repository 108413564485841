// src/utils/firebaseConfig.ts
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Choose the configuration based on a condition (e.g., route, environment, etc.)
const isSpecialConfig = window.location.pathname === '/verify-email' || window.location.pathname === '/verify-email-change';

const firebaseConfig = isSpecialConfig
  ? {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    }
  : {
      apiKey: process.env.REACT_APP_API_KEY_CYBERHEADLINERS,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN_CYBERHEADLINERS,
      projectId: process.env.REACT_APP_PROJECT_ID_CYBERHEADLINERS,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET_CYBERHEADLINERS,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_CYBERHEADLINERS,
      appId: process.env.REACT_APP_APP_ID_CYBERHEADLINERS,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID_CYBERHEADLINERS,
    };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, analytics, auth, db, storage, firebaseConfig };
