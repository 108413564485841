// src/App.tsx
import React from 'react';
import ReactGA from 'react-ga4';
import './App.css';
import './styles/index.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import SharedLayout from './context/SharedLayout';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ConfirmNewAccountVerificationPage from './pages/ConfirmNewAccountVerificationPage';

ReactGA.initialize('G-TY25WER9RZ');

library.add(fas);
library.add(fab);

const App = () => {  
  return (
    <Router>
      <Routes>
        {/* Email verification routes */}
        <Route path='/verify-email' element={<ConfirmNewAccountVerificationPage />} />
        <Route path="/" element={<SharedLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
          {/* Catch-all route - redirects any unmatched paths to /home */}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
